(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-win-message/assets/javascripts/utils/constants.js') >= 0) return;  svs.modules.push('/components/tur/bubble-win-message/assets/javascripts/utils/constants.js');
"use strict";


svs.bubble_win_message = svs.bubble_win_message || {};
svs.bubble_win_message.extraIcon = {
  CAR_ICON: 'car',
  CAR_ICON_LEIA: 'CAR_WIN',
  DAY_ICON: 'dag',
  DAY_WIN_ICON: 'dagsvinst',
  KLOVER_ICON: 'klover',
  KLOVER_ICON_LEIA: 'MONTH_WIN_TRISS',
  KLOVER_ICON_DBLTRISS_LEIA: 'MONTH_WIN_DBLTRISS',
  STAR_WIN_TRISS: 'STAR_WIN_TRISS',
  TV_ICON: 'tv',
  TV_ICON_LEIA: 'TV_WIN_TRISS',
  TV_ICON_DBLTRISS_LEIA: 'TV_WIN_DBLTRISS',
  WEEK_ICON: 'vecka',
  WEEK_ICON_LEIA: 'WEEK_WIN',
  WEEK_50000X6_ICON_LEIA: 'WEEK_WIN_50000X6',
  UNKNOWN_HIGH_WIN: 'UNKNOWN_HIGH_WIN'
};
svs.bubble_win_message.headerMessage = {
  SCRATCH_DEMO_HEADER: 'Du har skrapat en provlott.',
  PLAYED_DEMO_HEADER: 'Du har spelat en provlott.',
  PLAYED_DEMO_HEADER_PACMAN: 'Du har spelat ett provspel.',
  TRISS_WIN_HEADER: 'Plötsligt händer det!',
  WIN_DEFAULT_HEADER: 'Grattis!',
  NO_WIN_HEADER: 'Tyvärr ingen vinst denna gång.'
};
svs.bubble_win_message.bankAdvice = {
  END_DESCRIPTION: 'Vänligen kontakta kundservice så hjälper vi dig med din vinst,',
  TV_DESCRIPTION: 'Där avgörs hur stor din vinst blir – från 100 000 upp till 5 000 000 kronor.',
  KLOVER_DESCRIPTION: 'Exakt summa och antal år avgörs när du skrapar fram din vinst i TV4.',
  CAR_END_DESCRIPTION: 'Vänligen kontakta kundservice så hjälper vi dig med din vinst,',
  DAY_WEEK_ICON_DESCRIPTION: 'Vinsten betalas ut till ditt bankkonto efter kontakt med Svenska Spel.',
  DEFAULT_END_DESCRIPTION: 'Vänligen kontakta kundservice så hjälper vi dig med din vinst,',
  STAR_WIN_DESCRIPTION: 'Vinsten har satts in på ditt spelkonto.'
};
svs.bubble_win_message.demoMessage = {
  PACMAN: 'Du vinner om du hittar fyra likadana vinstsymboler.',
  SKRAPKRYSS: 'Du vinner vid 3 eller fler helt markerade ord.',
  SKRAPLOTTO: 'Du vinner vid 4 rätt eller fler, eller minst 2 jokersymboler.',
  SKRAPMATCHA: 'Du vinner om du matchar turnummer och nummer i undre fältet.',
  SKRAPBINGO: 'Du vinner vid markerad hel rad, 4 hörn, kryss (X) eller inre fyrkant.',
  SKRAPKENO: 'Du vinner vid helt markerad rad eller del av rad i respektive Kenorad (1-11). Kung Keno dubblerar vinsten på raden.',
  SKRAP7AN: 'Du vinner om du hittar en eller flera 7:or i spel 1-8, om tärningarnas summa blir 7 eller dominobrickans summa blir 7.',
  TRISS90: 'Du vinner vid 3 lika belopp, eller hittar 1 eller 2 Triss i extraspelet.',
  TREIRAD: 'Du vinner om du får 3 i rad vågrätt, lodrätt eller diagonalt.',
  SJUTTONELLERMER: 'Du vinner om summan är 17 eller mer på någon rad, lodrätt eller vågrätt.',
  MITTEMELLAN: 'Du vinner om "mitt nummer" är mellan det låga och det höga numret på raden.',
  DEFAULT: 'Vid vinst ringas vinstbeloppen in.' 
};
svs.bubble_win_message.winMessage = {
  CAR_WIN_MESSAGE: 'Du har vunnit en bil!',
  DAY_WIN_MESSAGE: 'Du har vunnit Dagsvinsten och får 10\u00a0000\u00a0kr VARJE dag i ett helt år.',
  DEFAULT_WIN_MESSAGE: 'Vinsten har satts in på ditt spelkonto.',
  KLOVER_WIN_MESSAGE: 'Du har vunnit Månadsklöver och är garanterad en summa varje månad i ett antal år.',
  STAR_WIN_MESSAGE: 'Du har vunnit Stjärnvinsten, alla belopp på skrapfältet!',
  TICKET_WIN_MESSAGE: 'Lotten hittar du i Mina spel. Grattis!',
  TICKETS_WIN_MESSAGE: 'Lotterna hittar du i Mina spel. Grattis!',
  TV_WIN_MESSAGE: 'Du har vunnit TV-Triss och får skrapa fram din vinst i TV4!',
  WEEK_WIN_MESSAGE: 'Du har vunnit Veckovinsten och får 15\u00a0000\u00a0kr VARJE vecka i ett helt år.',
  WEEK_WIN_50000X6_MESSAGE: 'Du har vunnit en veckovinst och får 50\u00a0000\u00a0kr i veckan i sex veckor!'
};
svs.bubble_win_message.winAmount = {
  DEMO_PLAYED_WIN_AMOUNT_TEXT: 'Om du spelat en riktig lott hade du vunnit ',
  DEMO_WIN_AMOUNT_TEXT: 'Om du skrapat en riktig lott hade du vunnit ',
  WIN_AMOUNT_TEXT: 'Du har vunnit ',
  TOTAL_AMOUNT_TEXT: 'Totalt blev vinsten '
};
svs.bubble_win_message.extraWinNumber = {
  1: 'en',
  2: 'två'
};
if (typeof exports !== 'undefined') {
  module.exports = svs.bubble_win_message;
}

 })(window);
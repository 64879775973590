(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-win-message/assets/javascripts/components/bubble-win-message.js') >= 0) return;  svs.modules.push('/components/tur/bubble-win-message/assets/javascripts/components/bubble-win-message.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

setGlobal('svs.tur.bubbleWinMessage.components.BubbleWinMessage', _ref => {
  let {
    options,
    children
  } = _ref;
  const {
    demo = false,
    amount,
    extraicon,
    game,
    disableGrassroot
  } = options;
  const winMessage = svs.tur.bubbleWinMessage.utils.winMessage;
  const BigHeader = svs.tur.bubbleWinMessage.components.BigHeader;
  const SmallHeader = svs.tur.bubbleWinMessage.components.SmallHeader;
  const BankAdvice = svs.tur.bubbleWinMessage.components.BankAdvice;
  const Description = svs.tur.bubbleWinMessage.components.Description;
  const WinAmount = svs.tur.bubbleWinMessage.components.WinAmount;
  const usersettings = svs.tur.usersettings;
  const {
    useState
  } = React;
  const [recivers, setRecivers] = useState(false);
  const isWin = parseInt(amount, 10) > 0;
  const shouldFetchRecivers = !recivers && !demo && !isWin && !disableGrassroot && svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const formattedAmount = svs.utils.format.Currency(options.amount);
  const Header = isWin && !demo ? BigHeader : SmallHeader;
  const {
    header,
    description,
    winAmountText
  } = winMessage(_objectSpread(_objectSpread({}, options), {}, {
    isWin,
    recivers
  }));
  if (shouldFetchRecivers) {
    usersettings.get({
      key: 'receivers'
    }).done(_ref2 => {
      let {
        value
      } = _ref2;
      setRecivers(value);
    });
    return null;
  }
  return React.createElement("div", {
    className: "bubble-win-container",
    "data-testid": "bubble-win-container-test-id",
    role: "alert"
  }, React.createElement("div", {
    className: "bubble-win-message ".concat((!description || description.length < 80) && 'tiny-tail'),
    "data-testid": "bubble-win-message-test-id"
  }, React.createElement(Header, {
    header: header
  }), React.createElement("div", {
    className: "bubble-win-message-wrapper"
  }, children && children, !children && !extraicon && isWin && React.createElement(WinAmount, {
    amount: formattedAmount,
    extraGameWin: game && game.extragame,
    winText: winAmountText
  }), description && React.createElement(Description, {
    description: description
  }), (amount >= 1000000 || extraicon) && React.createElement(BankAdvice, {
    extraicon: extraicon
  }))));
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-win-message/assets/javascripts/components/big-header.js') >= 0) return;  svs.modules.push('/components/tur/bubble-win-message/assets/javascripts/components/big-header.js');
"use strict";


setGlobal('svs.tur.bubbleWinMessage.components.BigHeader', _ref => {
  let {
    header
  } = _ref;
  return React.createElement("div", {
    className: "bubble-win-big-header bubble-win-header",
    "data-testid": "bubble-win-big-header-test-id"
  }, header);
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-win-message/assets/javascripts/utils/win-message.js') >= 0) return;  svs.modules.push('/components/tur/bubble-win-message/assets/javascripts/utils/win-message.js');
"use strict";

const {
  mapProductType
} = svs.ticketgames.ticketMappings.productMappings;
setGlobal('svs.tur.bubbleWinMessage.utils.winMessage', _ref => {
  let {
    demo,
    product,
    isWin,
    recivers,
    extraicon,
    game,
    amount
  } = _ref;
  const formatedProductName = mapProductType(product).toUpperCase();
  let header;
  let description;
  let winAmountText;
  if (demo) {
    if (_.contains(['TREIRAD', 'SJUTTONELLERMER', 'MITTEMELLAN'], formatedProductName)) {
      header = svs.bubble_win_message.headerMessage.PLAYED_DEMO_HEADER;
    } else if (formatedProductName === 'PACMAN') {
      header = svs.bubble_win_message.headerMessage.PLAYED_DEMO_HEADER_PACMAN;
    } else {
      header = svs.bubble_win_message.headerMessage.SCRATCH_DEMO_HEADER;
    }
    if (!isWin) {
      description = svs.bubble_win_message.demoMessage[formatedProductName] || svs.bubble_win_message.demoMessage.DEFAULT;
    } else {
      winAmountText = formatedProductName === 'PACMAN' ? svs.bubble_win_message.winAmount.DEMO_PLAYED_WIN_AMOUNT_TEXT : svs.bubble_win_message.winAmount.DEMO_WIN_AMOUNT_TEXT;
    }
  } else {
    if (isWin) {
      winAmountText = svs.bubble_win_message.winAmount.WIN_AMOUNT_TEXT;
      if (_.contains(['TRISS30', 'TRISS40', 'TRISS90', 'MINITRISS', 'DUBBELTRISS', 'BILTRISS'], formatedProductName)) {
        header = svs.bubble_win_message.headerMessage.TRISS_WIN_HEADER;
      } else {
        header = svs.bubble_win_message.headerMessage.WIN_DEFAULT_HEADER;
      }
      if (game && game.extragame) {
        if (game.extragame > 1) {
          description = svs.bubble_win_message.winMessage.TICKETS_WIN_MESSAGE;
        } else {
          description = svs.bubble_win_message.winMessage.TICKET_WIN_MESSAGE;
        }
      } else {
        switch (extraicon) {
          case svs.bubble_win_message.extraIcon.TV_ICON:
          case svs.bubble_win_message.extraIcon.TV_ICON_LEIA:
          case svs.bubble_win_message.extraIcon.TV_ICON_DBLTRISS_LEIA:
            description = svs.bubble_win_message.winMessage.TV_WIN_MESSAGE;
            break;
          case svs.bubble_win_message.extraIcon.KLOVER_ICON:
          case svs.bubble_win_message.extraIcon.KLOVER_ICON_LEIA:
          case svs.bubble_win_message.extraIcon.KLOVER_ICON_DBLTRISS_LEIA:
            description = svs.bubble_win_message.winMessage.KLOVER_WIN_MESSAGE;
            break;
          case svs.bubble_win_message.extraIcon.DAY_ICON:
          case svs.bubble_win_message.extraIcon.DAY_WIN_ICON:
            description = svs.bubble_win_message.winMessage.DAY_WIN_MESSAGE;
            break;
          case svs.bubble_win_message.extraIcon.WEEK_ICON:
          case svs.bubble_win_message.extraIcon.WEEK_ICON_LEIA:
            description = svs.bubble_win_message.winMessage.WEEK_WIN_MESSAGE;
            break;
          case svs.bubble_win_message.extraIcon.WEEK_50000X6_ICON_LEIA:
            description = svs.bubble_win_message.winMessage.WEEK_WIN_50000X6_MESSAGE;
            break;
          case svs.bubble_win_message.extraIcon.STAR_WIN_TRISS:
            {
              description = React.createElement('span', null, svs.bubble_win_message.winMessage.STAR_WIN_MESSAGE, React.createElement('div', null, svs.bubble_win_message.winAmount.TOTAL_AMOUNT_TEXT, React.createElement('span', {
                className: 'bold'
              }, "".concat(svs.utils.format.Currency(amount), " kr"))));
              break;
            }
          case svs.bubble_win_message.extraIcon.CAR_ICON:
          case svs.bubble_win_message.extraIcon.CAR_ICON_LEIA:
            description = svs.bubble_win_message.winMessage.CAR_WIN_MESSAGE;
            break;
          case svs.bubble_win_message.extraIcon.UNKNOWN_HIGH_WIN:
            description = null;
            break;
          default:
            description = svs.bubble_win_message.winMessage.DEFAULT_WIN_MESSAGE;
        }
      }
    } else {
      header = svs.bubble_win_message.headerMessage.NO_WIN_HEADER;
      if (recivers && recivers.length) {
        if (recivers.length === 1) {
          description = "Men ".concat(recivers[0], " tackar f\xF6r ditt st\xF6d genom Gr\xE4sroten.");
        } else {
          const lastReciverIndex = recivers.length - 1;
          description = "Men ".concat(recivers.reduce((a, v, i) => "".concat(a, " ").concat(i === 0 ? '' : i === lastReciverIndex ? 'och ' : ', ').concat(v), ''), " tackar f\xF6r ditt st\xF6d genom Gr\xE4sroten.");
        }
      }
    }
  }
  return {
    header,
    description,
    winAmountText
  };
});

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-win-message/assets/javascripts/components/bank-advice.js') >= 0) return;  svs.modules.push('/components/tur/bubble-win-message/assets/javascripts/components/bank-advice.js');
"use strict";


setGlobal('svs.tur.bubbleWinMessage.components.BankAdvice', _ref => {
  let {
    extraicon
  } = _ref;
  let description = '';
  let endDescription = svs.bubble_win_message.bankAdvice.END_DESCRIPTION;
  const IS_STAR_WIN = extraicon === svs.bubble_win_message.extraIcon.STAR_WIN_TRISS;
  switch (extraicon) {
    case svs.bubble_win_message.extraIcon.TV_ICON:
    case svs.bubble_win_message.extraIcon.TV_ICON_LEIA:
      description = svs.bubble_win_message.bankAdvice.TV_DESCRIPTION;
      break;
    case svs.bubble_win_message.extraIcon.KLOVER_ICON:
    case svs.bubble_win_message.extraIcon.KLOVER_ICON_LEIA:
      description = svs.bubble_win_message.bankAdvice.KLOVER_DESCRIPTION;
      break;
    case svs.bubble_win_message.extraIcon.CAR_ICON:
    case svs.bubble_win_message.extraIcon.CAR_ICON_LEIA:
      endDescription = svs.bubble_win_message.bankAdvice.CAR_END_DESCRIPTION;
      break;
    case svs.bubble_win_message.extraIcon.STAR_WIN_TRISS:
      description = svs.bubble_win_message.bankAdvice.STAR_WIN_DESCRIPTION;
      break;
    case svs.bubble_win_message.extraIcon.DAY_ICON:
    case svs.bubble_win_message.extraIcon.DAY_WIN_ICON:
    case svs.bubble_win_message.extraIcon.WEEK_ICON:
    case svs.bubble_win_message.extraIcon.WEEK_ICON_LEIA:
      description = svs.bubble_win_message.bankAdvice.DAY_WEEK_ICON_DESCRIPTION;
      break;
    default:
      endDescription = svs.bubble_win_message.bankAdvice.DEFAULT_END_DESCRIPTION;
  }
  return React.createElement("div", {
    className: "bubble-win-bank-advice margin-top-1",
    "data-testid": "bubble-win-bank-advice-test-id"
  }, !extraicon && React.createElement("p", {
    className: "bold bank-advice-message"
  }, "Vill du ha bankr\xE5dgivning?"), description && React.createElement("p", {
    className: "margin-top-1 bank-advice-description"
  }, description), !IS_STAR_WIN && React.createElement("p", {
    className: "bank-advice-end-description"
  }, endDescription, " ", React.createElement("a", {
    href: "tel:0770-11 11 11"
  }, "0770-11 11 11")));
});

 })(window);
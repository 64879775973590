(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/bubble-win-message/assets/javascripts/components/win-amount.js') >= 0) return;  svs.modules.push('/components/tur/bubble-win-message/assets/javascripts/components/win-amount.js');
"use strict";


setGlobal('svs.tur.bubbleWinMessage.components.WinAmount', _ref => {
  let {
    amount,
    winText = svs.bubble_win_message.winAmount.WIN_AMOUNT_TEXT,
    extraGameWin
  } = _ref;
  return React.createElement("div", {
    "data-testid": "bubble-win-message-winamount-test-id"
  }, winText, React.createElement("span", {
    className: "bubble-win-amount bold"
  }, extraGameWin ? "".concat(svs.bubble_win_message.extraWinNumber[extraGameWin], " Triss \xE1 30 kr") : "".concat(amount, " kr")));
});

 })(window);